import {
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase-config";
import { isArray } from "lodash";

export const setTrialWords = async (quizColl, baseDifficulty = 0) => {
  const data = [];
  if (parseInt(baseDifficulty) === 0 || !baseDifficulty) {
    const q = query(collection(db, quizColl), where("isTrial", "==", true));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      data.push(doc.data());
    });
  } else {
    const q = query(
      collection(db, quizColl),
      where("difficulty", "==", +baseDifficulty),
      where("isTrial", "==", true)
    );
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      data.push(doc.data());
    });
  }
  localStorage.setItem("currentWord", JSON.stringify(data));
  window.dispatchEvent(new Event("storage"));
  return data;

};

export const fetchQuestionsFromCollection = async (quizColl, baseDifficulty = 0, difficultyPattern = []) => {
  let data = [];
  const difficultyPatternArray = difficultyPattern
    .replace(/[\[\]]/g, '')  // Remove square brackets
    .split(',')
    .map(num => Number(num.trim())) ?? [];
  if (difficultyPatternArray?.length > 0) {
    //get all questions with difficultyPattern
    const q = query(
      collection(db, quizColl),
      where("difficulty", "in", difficultyPatternArray),
    );
    const querySnapshot = await getDocs(q);
    let tempData = [];
    querySnapshot.forEach((doc) => {
      if (!doc.data().isTrial) {
        tempData.push(doc.data());
      }
    });

    difficultyPatternArray.forEach((difficulty) => {
      let temp = tempData.filter((item) => item.difficulty === +difficulty);
      let randomIndex = Math.floor(Math.random() * temp.length);
      data.push(temp[randomIndex]);
    });
  }

  else if (parseInt(baseDifficulty) === 0 || !baseDifficulty) {
    const q = query(collection(db, quizColl));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      data.push(doc.data());
    });
  } else {
    const q = query(
      collection(db, quizColl),
      where("difficulty", "==", +baseDifficulty),
    );
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      data.push(doc.data());
    });
    data = data.filter((item) => !item.isTrial);
  }
  return data;
};